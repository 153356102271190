<div class="cal-outer" [class.job]="data.type == Constant.tlJobOffer">
  <div class="cal-inner" id="cal-inner" #calElm>
    <div class="contents box" [class.noshadow]="data.type == Constant.tlJobOffer"
    *ngFor="let contents of data.list; index as i" [class.opacity]="i>sliderNo+2 || i<sliderNo">
    <div class="rect">
      <img src="assets/company/rank{{i+1}}.png" class="rank-icon" *ngIf="data.type == Constant.rpContentsList">
      <div class="image-container">
        <a *ngIf="contents.image_url" routerLink="/{{detailUrl}}/{{data.companyCode}}/{{contents.code}}">
          <img class="image" [src]="contents.image_url" appImageLoadingError />
          <div class="image-back" [style.background-image]="'url(' + contents.image_url + ')'"></div>
        </a>
        <a *ngIf="contents.image_url===''" routerLink="/{{detailUrl}}/{{data.companyCode}}/{{contents.code}}">
          <img class="image noimage" lazyLoad="assets/common/noimage.png" appImageLoadingError />
          <div class="image-back-noimage"></div>
        </a>
      </div>
      <div class="title-container">
        <a class="title" routerLink="/{{detailUrl}}/{{data.companyCode}}/{{contents.code}}">{{ contents.title }}</a>
      </div>
      <div class="like-area" *ngIf="detailUrl == Constant.rpContentsDetail">
        <div class="like" (click)="onLikeButton(contents)" [class.liked]="contents.good_flag == 1" [class.disabled]="isLikePosting">
          <img src="assets/company/icon_heart_R.png" *ngIf="contents.good_flag != 1">
          <img src="assets/company/icon_heart_W.png" *ngIf="contents.good_flag == 1">
          <div>{{ contents.good_cnt }}</div>
        </div>
      </div>
      <div *ngIf="data.type == Constant.tlJobOffer && (contents.ismind != '' || contents.isapply != '')" class="like-area action">
        <div *ngIf="contents.ismind != '' && contents.isapply == ''">
          <span>気になる済 </span><span class="actiondate">{{ contents.ismind }}</span>
        </div>
        <div *ngIf="contents.isapply != ''">
          <span>応募済 </span><span class="actiondate">{{ contents.isapply }}</span>
        </div>
      </div>
    </div>
      <div class="box-bottom" *ngIf="data.type == Constant.tlContents">
        <a routerLink="/{{Constant.rpContentsCategory}}/{{data.companyCode}}/{{contents.category_url}}" *ngIf="contents.category_url">
          <div class="category">{{ contents.category }}</div>
        </a>
      </div>
      <div class="box-bottom" *ngIf="data.type == Constant.rpContentsDetail || data.type == Constant.rpContentsList">
        <div class="tag-area">
          <div *ngIf="contents.tag1Text" class="tag">#{{ contents.tag1Text }}</div>
          <div *ngIf="contents.tag2Text" class="tag">#{{ contents.tag2Text }}</div>
          <div *ngIf="contents.tag3Text" class="tag">#{{ contents.tag3Text }}</div>
          <div *ngIf="contents.tag4Text" class="tag">#{{ contents.tag4Text }}</div>
          <div *ngIf="contents.tag5Text" class="tag">#{{ contents.tag5Text }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="cal-prev" (click)="prevSlide()" *ngIf="sliderNo>0"><img src="/assets/company/slide_left.png"></div>
  <div class="cal-next" (click)="nextSlide()" *ngIf="sliderNo+3<data.list.length"><img src="/assets/company/slide_right.png"></div>
</div>
