<div>
  <div class="header-title"
    [class.margin-thin]="companyInfo.page == Constant.rpContentsCategory || companyInfo.page == Constant.rpContentsDetail || companyInfo.page == Constant.rpJobOfferDetail"
    >COMPANY PROFILE</div>
  <div class="flex profile-area">
    <div class="left">
      <div class="circle">
        <div class="logo-area"><img [src]="companyInfo.logoUrl" *ngIf="companyInfo.logoUrl"></div>
      </div>
    </div>
    <div class="right">
      <div class="row flex">
        <div class="label">会社名</div>
        <div class="text" *ngIf="!companyInfo.homepage">{{ companyInfo.name }}</div>
        <div class="text" *ngIf="companyInfo.homepage"><a [href]="companyInfo.homepage" target="_blank">{{ companyInfo.name }}<img src="assets/company/icon_launch.png" class="launch-icon"></a></div>
      </div>
      <div class="row flex">
        <div class="label">業種</div>
        <div class="text">
          <span *ngFor="let text of companyInfo.industryAry; last as last">{{text}}<ng-container *ngIf="!last">、</ng-container></span>
        </div>
      </div>
      <div class="row flex" *ngIf="companyInfo.establishment">
        <div class="label">設立</div>
        <div class="text">{{ companyInfo.establishment }}</div>
      </div>
      <div class="row flex" *ngIf="companyInfo.representative_name">
        <div class="label">代表</div>
        <div class="text">{{ companyInfo.representative_name }}</div>
      </div>
      <div class="row flex" *ngIf="companyInfo.capital">
        <div class="label">資本金</div>
        <div class="text">{{ companyInfo.capital }}円</div>
      </div>
      <div class="row flex" *ngIf="companyInfo.employees">
        <div class="label">従業員</div>
        <div class="text">{{ companyInfo.employees }}人</div>
      </div>
      <div class="row flex" *ngIf="companyInfo.address">
        <div class="label">本社</div>
        <div class="text">{{ companyInfo.address }}</div>
      </div>
      <div class="sns-area">
        <div class="flex">
          <a *ngIf="companyInfo.link_x" [href]="companyInfo.link_x" target="_blank"><img src="assets/company/sns_x.png" ></a>
          <a *ngIf="companyInfo.link_fb" [href]="companyInfo.link_fb" target="_blank"><img src="assets/company/sns_facebook.png"></a>
          <a *ngIf="companyInfo.link_ig" [href]="companyInfo.link_ig" target="_blank"><img src="assets/company/sns_Instagram.png"></a>
          <a *ngIf="companyInfo.link_tt" [href]="companyInfo.link_tt" target="_blank"><img src="assets/company/sns_tiktok.png"></a>
          <a *ngIf="companyInfo.link_linkedin" [href]="companyInfo.link_linkedin" target="_blank" class="li"><img src="assets/company/sns_linkedin.png"></a>
          <a *ngIf="companyInfo.link_note" [href]="companyInfo.link_note" target="_blank"><img src="assets/company/sns_note.png"></a>
        </div>
        <div class="flex">
          <ng-container *ngFor="let link of linkCnt; index as i">
            <a *ngIf="companyInfo['link_name' + (i+1)] && companyInfo['link_other' + (i+1)]" [href]="companyInfo['link_other' + (i+1)]" target="_blank" class="other">{{ companyInfo['link_name' + (i+1)] }}</a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <mat-divider class="footer-line"></mat-divider>
  <div class="footer-link flex">
    <a routerLink="/{{entryUrl}}" (click)="onTop()" class="home">ホーム</a>
    <a routerLink="/{{ Constant.rpCompanyAbout }}/{{ companyInfo.code }}" (click)="onTop()">会社について</a>
    <a routerLink="/{{ Constant.rpContentsList }}/{{ companyInfo.code }}" (click)="onTop()" *ngIf="companyInfo.cts_cnt>0">コンテンツ</a>
    <a routerLink="/{{ Constant.rpJobOfferList}}/{{ companyInfo.code }}" (click)="onTop()" *ngIf="companyInfo.jo_cnt>0">求人情報</a>
  </div>
  <div class="powered">
    <a routerLink="/" target="_blank">Powered by CaLin</a>
    <div class="right">
      <a class="" href="{{Constant.footerUrlAgreement}}" target="_blank"><span>利用規約</span><img src="assets/company/icon_launch.png" class="launch-icon"></a>
      <a class="" href="{{Constant.footerUrlPrivacy}}" target="_blank"><span>プライバシーポリシー</span><img src="assets/company/icon_launch.png" class="launch-icon"></a>
    </div>
  </div>
</div>
@if (companyInfo.page != Constant.rpContentsDetail && companyInfo.page != Constant.rpJobOfferDetail) {
  <div class="space"></div>
  <div [id]="Constant.elementIdFooterBtn">
    <div class="tsunagaru-btn" (click)="onTsunagaru()" [class.disabled]="registering">
      <img src="assets/company/Handshake.png">
      <div>つながる</div>
    </div>
  </div>
}

