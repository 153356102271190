<mat-toolbar class="header" *ngIf="auth.loggedIn.value && !showSpMenu">
  <div class="left-container">
    <div class="header-logo">
      <a (click)="onMypage()">
        <img src="assets/common/logo.png"
          alt="CaLin" class="logo" />
      </a>
    </div>
    <h1 class="header-text">
      はじめまして！<br>
      わたしの気になる会社
    </h1>
  </div>
  <div class="right-container" id="mypage-header">
    <a (click)="onMypage()">
      <button mat-icon-button class="menu-btn">
        <mat-icon class="menu-icon">person</mat-icon>
        <div class="menu-text">マイページ</div>
      </button>
    </a>
  </div>
</mat-toolbar>

<!-- 企業別ヘッダー -->
<div [class.space]="menuFixed" *ngIf="companyData">
  <div class="back" [class.fixed]="menuFixed">
    <div class="logo-area">
        <a routerLink="/{{entryUrl}}" (click)="onTopPage()" *ngIf="logoUrl"><img [src]="logoUrl" class="logo"/></a>
        <div class="name">{{ companyData.name }}</div>
    </div>
    <div class="right">
      <div class="show-pc link-area">
        <a class="menu" routerLink="/{{entryUrl}}" (click)="onTopPage(Constant.rpCompanyTop)" [class.active]="companyData.page==Constant.rpCompanyTop">ホーム</a>
        <a class="menu" routerLink="/{{ Constant.rpCompanyAbout }}/{{ companyData.code }}" (click)="onTopPage(Constant.rpCompanyAbout)" [class.active]="companyData.page==Constant.rpCompanyAbout">会社について</a>
        <a class="menu" routerLink="/{{ Constant.rpContentsList }}/{{ companyData.code }}" (click)="onTopPage(Constant.rpContentsList)" [class.active]="companyData.page==Constant.rpContentsList" *ngIf="companyData.cts_cnt>0">コンテンツ</a>
        <a class="menu" routerLink="/{{ Constant.rpJobOfferList}}/{{ companyData.code }}"  (click)="onTopPage(Constant.rpJobOfferList)" [class.active]="companyData.page==Constant.rpJobOfferList" *ngIf="companyData.jo_cnt>0">求人情報</a>
        <a class="tsunagaru-btn" (click)="onTsunagaru()" [class.disabled]="registering">
          <img src="assets/company/Handshake.png">
          <div>つながる</div>
        </a>
        <div class="what-tsunagaru" (click)="onWhatIsTalentPool()">
          <!-- <img src="assets/company/tsunagaru.png"> -->
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><defs><style>.a{fill:#eb7655;}</style></defs><path class="a" d="M11,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm.9,15.3H10.1V15.5h1.8Zm1.863-6.975-.81.828A3.064,3.064,0,0,0,11.9,13.7H10.1v-.45A3.623,3.623,0,0,1,11.153,10.7l1.116-1.134A1.76,1.76,0,0,0,12.8,8.3a1.8,1.8,0,0,0-3.6,0H7.4a3.6,3.6,0,0,1,7.2,0A2.864,2.864,0,0,1,13.763,10.325Z" transform="translate(-2 -2)"/></svg>
        </div>
      </div>
      <div class="show-sp mb-menu">
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" #trigger [matMenuTriggerFor]="menu" class="menu-btn" (menuOpened)="onMenuOpen()" (menuClosed)="onMenuClose()">
          <path d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25Z" fill="white"/>
          <path d="M12 25.0001C12 24.2024 12.6467 23.5557 13.4444 23.5557H36.5556C37.3533 23.5557 38 24.2024 38 25.0001C38 25.7979 37.3533 26.4446 36.5556 26.4446H13.4444C12.6467 26.4446 12 25.7979 12 25.0001Z" fill="#25A99F" class="a"/>
          <path d="M12 13.4444C12 12.6467 12.6467 12 13.4444 12H36.5556C37.3533 12 38 12.6467 38 13.4444C38 14.2422 37.3533 14.8889 36.5556 14.8889H13.4444C12.6467 14.8889 12 14.2422 12 13.4444Z" fill="#25A99F" class="a"/>
          <path d="M12 36.5558C12 35.758 12.6467 35.1113 13.4444 35.1113H36.5556C37.3533 35.1113 38 35.758 38 36.5558C38 37.3535 37.3533 38.0002 36.5556 38.0002H13.4444C12.6467 38.0002 12 37.3535 12 36.5558Z" fill="#25A99F" class="a"/>
        </svg>
        <!-- <img src="/assets/company/SP_menu.png" #trigger [matMenuTriggerFor]="menu" class="menu-btn" (menuOpened)="onMenuOpen()" (menuClosed)="onMenuClose()"> -->
        <mat-menu #menu="matMenu" class="sp-menu-panel" overlapTrigger="true">
          <div class="sp-menu-header">
            <div class="logo-area" (click)="onDisabledMenu($event)">
              <a routerLink="/{{entryUrl}}" (click)="onTopPage()" *ngIf="logoUrl"><img [src]="logoUrl" class="logo"/></a>
              <div class="name">{{ companyData.name }}</div>
            </div>
            <div class="right" (click)="onMenuClose()">
              <!-- <img src="/assets/company/close_green.png"> -->
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" class="close-btn">
                <rect width="33.9028" height="2.85893" rx="1.42947" transform="matrix(0.706986 0.707228 -0.706986 0.707228 2.03125 0)" fill="#25A99F" class="a"/>
                <rect width="33.903" height="2.85895" rx="1.42948" transform="matrix(0.707098 -0.707115 0.707098 0.707115 0 23.978)" fill="#25A99F" class="a"/>
              </svg>
            </div>
          </div>
          <div (click)="onDisabledMenu($event)" class="sp-menu-item">
            <a routerLink="/{{entryUrl}}" (click)="onTopPage()" [class.active]="companyData.page==Constant.rpCompanyTop">
              <div mat-menu-item class="mb-menu">
                <span>ホーム</span>
              </div>
            </a>
            <a routerLink="/{{Constant.rpCompanyAbout}}/{{ companyData.code }}" (click)="onTopPage()" [class.active]="companyData.page==Constant.rpCompanyAbout">
              <div mat-menu-item class="mb-menu">
                <span>会社について</span>
              </div>
            </a>
            <a routerLink="/{{Constant.rpContentsList}}/{{ companyData.code }}" (click)="onTopPage()" [class.active]="companyData.page==Constant.rpContentsList" *ngIf="companyData.cts_cnt>0">
              <div mat-menu-item class="mb-menu">
                <span>コンテンツ</span>
              </div>
            </a>
            <a routerLink="/{{Constant.rpJobOfferList}}/{{ companyData.code }}" (click)="onTopPage()" [class.active]="companyData.page==Constant.rpJobOfferList" *ngIf="companyData.jo_cnt>0">
              <div mat-menu-item class="mb-menu">
                <span>求人情報</span>
              </div>
            </a>
            <div class="mb-tsunagaru-area">
              <a class="tsunagaru-btn" (click)="onTsunagaru()" [class.disabled]="registering">
                <img src="assets/company/Handshake.png">
                <div>つながる</div>
              </a>
              <div class="what-tsunagaru-area" (click)="onWhatIsTalentPool()">
                <!-- <img src="assets/company/tsunagaru.png" > -->
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><defs><style>.a{fill:#eb7655;}</style></defs><path class="a" d="M11,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm.9,15.3H10.1V15.5h1.8Zm1.863-6.975-.81.828A3.064,3.064,0,0,0,11.9,13.7H10.1v-.45A3.623,3.623,0,0,1,11.153,10.7l1.116-1.134A1.76,1.76,0,0,0,12.8,8.3a1.8,1.8,0,0,0-3.6,0H7.4a3.6,3.6,0,0,1,7.2,0A2.864,2.864,0,0,1,13.763,10.325Z" transform="translate(-2 -2)"/></svg>
                <div>つながるとは</div>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
