<div id="tsunagaru">
  <div class="rect">
    <div class="title text-center">
      {{ data.display_name }}と つながりませんか？
    </div>
    <div class="tsunagaru-btn" (click)="onTsunagaru()" [class.disabled]="registering">
      <img src="assets/company/Handshake.png">
      <div>つながる</div>
    </div>
    <div class="what-tsunagaru-area" (click)="onWhatIsTalentPool()">
      <!-- <img src="assets/company/tsunagaru.svg" > -->
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><defs><style>.a{fill:#eb7655;}</style></defs><path class="a" d="M11,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm.9,15.3H10.1V15.5h1.8Zm1.863-6.975-.81.828A3.064,3.064,0,0,0,11.9,13.7H10.1v-.45A3.623,3.623,0,0,1,11.153,10.7l1.116-1.134A1.76,1.76,0,0,0,12.8,8.3a1.8,1.8,0,0,0-3.6,0H7.4a3.6,3.6,0,0,1,7.2,0A2.864,2.864,0,0,1,13.763,10.325Z" transform="translate(-2 -2)"/></svg>
      <div>つながるとは</div>
    </div>
  </div>
</div>
