import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { environment } from './../environments/environment';
import { Constant } from './constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  public lp = false;
  public widthAll = true;
  public companyHeader = false;
  private touchFlg;

  constructor(
    public auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    // 単独ページ判断（LP、エラー）
    this.router.events.forEach((event: NavigationEvent) => {
      // NavigationEnd
      if (event instanceof NavigationEnd) {
        // ヘッダーの表示/非表示
        if (event.urlAfterRedirects === '/' || event.url.indexOf('#') > -1
          || event.url.indexOf('/?') > -1
          || event.url.indexOf('/' + Constant.rpLpPage) > -1
          || event.url.indexOf(Constant.rpLpStartguide) > -1
          || event.url.indexOf(Constant.rpLpColumn) > -1
          || event.url.indexOf(Constant.rpMsgFile) > -1
          || event.url.indexOf(Constant.rpTmpFile) > -1
          || event.url.indexOf(Constant.rpAttachment + '/') > -1
          || event.urlAfterRedirects.includes(Constant.rpLogin + '/')
          || event.urlAfterRedirects.includes(Constant.rpJobOfferList)
          || event.urlAfterRedirects.includes(Constant.rpJobOfferDetail)
          || event.urlAfterRedirects.includes(Constant.rpContentsList)
          || event.urlAfterRedirects.includes(Constant.rpContentsCategory)
          || event.urlAfterRedirects.includes(Constant.rpContents)
          || event.urlAfterRedirects.includes(Constant.rpContentsDetail)
          || event.urlAfterRedirects.includes(Constant.rpMailUnsubscribe)
          || event.urlAfterRedirects.includes(Constant.rpCompanyAbout)
          ) {
          this.lp = true;
        } else {
          this.lp = false;
        }

        // 企業別ヘッダー表示有無
        if (event.urlAfterRedirects.includes(Constant.rpLogin + '/')
          || event.urlAfterRedirects.includes(Constant.rpCompanyAbout + '/')
          || event.urlAfterRedirects.includes(Constant.rpJobOfferList)
          || event.urlAfterRedirects.includes(Constant.rpJobOfferDetail)
          || event.urlAfterRedirects.includes(Constant.rpContentsList)
          || event.urlAfterRedirects.includes(Constant.rpContentsCategory)
          || event.urlAfterRedirects.includes(Constant.rpContentsDetail)
        ) {
          this.companyHeader = true;
        } else {
          this.companyHeader = false;
        }

        // 右メニューの表示/非表示
        if (
          event.urlAfterRedirects.match(Constant.rpConfirmmail) ||
          event.urlAfterRedirects.match(Constant.rpResendmail)
        ) {
          this.widthAll = false;
        } else if (
          event.url.indexOf('/page') > -1 ||
          event.urlAfterRedirects === '/' ||
          event.urlAfterRedirects.indexOf('/?') > -1 ||
          event.urlAfterRedirects.indexOf('/#') > -1 ||
          event.urlAfterRedirects.includes(Constant.rpLpStartguide) ||
          event.urlAfterRedirects.includes(Constant.rpLpColumn) ||
          event.urlAfterRedirects.includes(Constant.rpError) ||
          event.urlAfterRedirects.includes(Constant.rpLogin) ||
          event.urlAfterRedirects.includes(Constant.rpSignup) ||
          event.urlAfterRedirects.includes(Constant.rpConfirm) ||
          event.urlAfterRedirects.includes(Constant.rpResend) ||
          event.urlAfterRedirects.includes(Constant.rpPassForget) ||
          event.urlAfterRedirects.includes(Constant.rpJobOfferDetail) ||
          event.urlAfterRedirects.includes(Constant.rpTmpFile) ||
          event.urlAfterRedirects.includes(Constant.rpMsgFile) ||
          event.urlAfterRedirects.includes(Constant.rpAttachment + '/') ||
          event.urlAfterRedirects.includes(Constant.rpMaintenance) ||
          event.urlAfterRedirects.includes(Constant.rpJobOfferList) ||
          event.urlAfterRedirects.includes(Constant.rpContentsList) ||
          event.urlAfterRedirects.includes(Constant.rpContentsCategory) ||
          event.urlAfterRedirects.includes(Constant.rpContentsDetail) ||
          event.urlAfterRedirects.includes(Constant.rpMailUnsubscribe) ||
          event.urlAfterRedirects.includes(Constant.rpCompanyAbout)
          ) {
          this.widthAll = true;
        } else {
          this.widthAll = false;
        }
      }
    });

    // アナリティクストラッキングID
    gtag('js', new Date());
    gtag('config', environment.AnalyticsTrackingId);
  }

  ngAfterViewInit(): void {
    // android クラス追加
    const ua = window.navigator.userAgent.toLowerCase();
    if(ua.indexOf('android') !== -1){
      document.body.classList.add(Constant.elementClassAndroid);
    }
  }

  // 連続タップ禁止（拡大させないため）
  @HostListener('window:touchend', ['$event'])
  onTouchEnd(event: Event): void {
    if (this.touchFlg) {
      event.preventDefault();
    } else {
      this.touchFlg = true;
      setTimeout(() => {
        this.touchFlg = false;
      }, 500);
    }
  }
}
